exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-domaine-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=D:/APP WEB/gatsbyjs/odoip-gatsby/services/domaine/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-domaine-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-internet-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=D:/APP WEB/gatsbyjs/odoip-gatsby/services/internet/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-internet-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-voip-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=D:/APP WEB/gatsbyjs/odoip-gatsby/services/voip/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-d-app-web-gatsbyjs-odoip-gatsby-services-voip-index-mdx" */)
}

